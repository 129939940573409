import { render, staticRenderFns } from "./Email.vue?vue&type=template&id=3659671a&"
import script from "./Email.vue?vue&type=script&lang=ts&"
export * from "./Email.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormFieldGeneric: require('/home/cleavr/www.bchollos.es/releases/20240906090546579/components/form/field/Generic.vue').default,BtnGeneric: require('/home/cleavr/www.bchollos.es/releases/20240906090546579/components/btn/Generic.vue').default,FormFieldCheckbox: require('/home/cleavr/www.bchollos.es/releases/20240906090546579/components/form/field/Checkbox.vue').default})
