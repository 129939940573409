import { render, staticRenderFns } from "./GlobalFeatured.vue?vue&type=template&id=375e2428&"
import script from "./GlobalFeatured.vue?vue&type=script&lang=ts&"
export * from "./GlobalFeatured.vue?vue&type=script&lang=ts&"
import style0 from "./GlobalFeatured.vue?vue&type=style&index=0&id=375e2428&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MicroUserImage: require('/home/cleavr/www.bchollos.es/releases/20240906090546579/components/micro/userImage.vue').default,MicroHeatCounter: require('/home/cleavr/www.bchollos.es/releases/20240906090546579/components/micro/HeatCounter.vue').default,MicroHeatCounterVote: require('/home/cleavr/www.bchollos.es/releases/20240906090546579/components/micro/HeatCounterVote.vue').default})
